<template>
    <LiefengContent :isBack='true' @backClick="backClick">
        <!-- <template v-slot:title>社区架构导入数据同步</template> -->
        <template v-slot:title>
            导入并同步
            <!-- <Button
            type="error"  @click="$router.push(`/area?isLineHome=${$route.query.isLineHome}`)"  v-if="$route.query.isLineHome == 'true'&& !$route.query.isback" >返回</Button>
            <Button type="error" v-if="$route.query.isLineHome && $route.query.isback" @click="$router.push(`/mobilizationsystem?isLineHome=${$route.query.isLineHome}`)">返回</Button> -->
            </template>
        <template v-slot:toolsbarRight>
           <!-- <Tooltip placement="right-start"  style="width:200px;margin-right: 10px;">
                <div slot="content" style="width: 200px;white-space: normal">
                  由于社区太多，请输入关键字进行刷选
                </div>
                <Select
                  v-model="searchData.communityCode"
                  filterable
                  @on-change="getSearchTree"
                  placeholder="点击列表/输入搜索"
                  :remote-method="loadingCommunityFn"
                  :loading="loadingCommunity"
                >
                <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
                </Select>
            </Tooltip> -->
            <Form  :label-colon="true" :inline="true" class="search">
            <FormItem>
            <Input
                :maxlength="20"
                enter-button
                v-model.trim="searchData.name"
                placeholder="按区域名称"
                style="margin-right:10px;width: 150px"
                @on-enter="getList"
            />
            </FormItem>
            <FormItem>
             <Select transfer
                placeholder="数据状态"
                v-model="searchData.syncStatus" style="width:200px;margin-right: 10px;"
            >
                <Option :value="allStatus">全部</Option>
                <Option value="1">未同步</Option>
                <Option value="2">同步成功</Option>
                <Option value="3">同步失败</Option>
            </Select>
            </FormItem>
            <Button style="margin: 0 4px" type="primary" icon="ios-search" @click="searchBtn">查询</Button>
            <Button style="margin: 0 4px" type="success" icon="ios-refresh" @click="restBtn">重置</Button>
            <Button style="margin: 0 4px" type="primary" @click="importExcel" icon="ios-add-circle-outline">导入</Button>
            <Button style="margin: 0 4px" type="error" @click="delIds">批量删除({{SelectNum}})</Button>

              
            <Button style="margin: 0 4px" type="primary" :loading="syncLoading" @click="syncBtn">批量同步({{SelectNum}})</Button>
            <Button style="margin: 0 4px" type="error" @click="deleteAll">删除全部</Button>
              <!-- <Button
          type="error"
          icon="ios-arrow-back"
          @click="navigateBack"
          v-if="$route.query.isLineHome == ''"
          >返回</Button> -->
          </Form>
        </template>
         <template v-slot:contentArea>
             <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
                @tableSelect="tableSelect"
                ref="baseList"
            ></LiefengTable>
             <!--居民操作纪录-->
            <LiefengModal
                :value="operationModal"
                title="组织机构导入修改"
                @input="operationStatus"
                :fullscreen="false"
                width="500px"
                height="500px"
            >
                <template v-slot:contentarea>
                    <div>
                        <Form  label-position="right" :label-width="120">
                 
                            <FormItem>
                            <span slot="label" class="validate">街道名称:</span>
                            <Input placeholder="请输入街道名称" v-model.trim="editRow.streetName" :maxlength="20"></Input>
                            </FormItem>
                            <FormItem>
                            <span slot="label" class="validate">小区名称:</span>
                            <Input placeholder="请输入小区名称" v-model.trim="editRow.projectName" :maxlength="20"></Input>
                            </FormItem>
                            <FormItem>
                            <span slot="label" class="validate">楼栋名称:</span>
                            <Input placeholder="请输入楼栋名称" v-model.trim="editRow.buildingName" :maxlength="20"></Input>
                            </FormItem>
                            <FormItem>
                            <span slot="label" class="validate">房号/门牌号:</span>
                            <Input placeholder="请输入房号/门牌号" v-model.trim="editRow.houseNum" :maxlength="30"></Input>
                            </FormItem>
                            <FormItem>
                            <span slot="label" >排序:</span>
                            <InputNumber :min="0" v-model="editRow.seq"></InputNumber>
                        </FormItem>
                        </Form>
     
                    </div>
                </template>
                <template v-slot:toolsbar>
                 <Button style="margin: 0 5px 0 13px" type="warning" @click="operationTip=true;operationStatus(false);">取消</Button>   
                <Button style="margin: 0 5px 0 13px" type="primary" @click="saveOperation">确定</Button>
                </template>
            </LiefengModal>
            <!-- 导入excel弹窗 -->
      <LiefengModal title="导入" width="40%" height="200px" :value="excelStatus" @input="excelModalData" class="export-modal">
        <template v-slot:contentarea>
            <!-- <Form :label-width="100">
            <FormItem>
               <span slot="label" class="validate">请选择社区:</span>
               <Tooltip placement="right-start"  style="width:100%">
                <div slot="content" style="width: 200px;white-space: normal">
                  由于社区太多，请输入关键字进行刷选
                </div>
                <Select
                  v-model="nodeId"
                  filterable
                  @on-change="getTree"
                  placeholder="点击列表/输入搜索"
                  :remote-method="loadingCommunityFn"
                  :loading="loadingCommunity"
                >
                防止报错，使用了index
                <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
                </Select>
            </Tooltip>
            </FormItem>
          </Form> -->
          <LiefengUploadExcel style="margin:20px 0" :showDownModal="{isShow:true,downLink:'./excelfiles/orgmodal.xlsx',downName:'社区架构导入模板'}" :headers="headers" :action="exportAction" :uploadData="uploadData" :isShow='showExcel' @success="excelSuccess"></LiefengUploadExcel>
        </template>
      </LiefengModal>
        </template>
       
    </LiefengContent>
    
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import LiefengUploadExcel from './childrens/LiefengUploadExcel.vue'
export default {
    components:{LiefengContent,LiefengTable,LiefengModal,LiefengUploadExcel},
    data(){
        return{
            allStatus:0,
            talbeColumns:[
                {
                    type: "selection",
                    width: 60,
                    align: "center"
                },
                 {
                    title: "社区",
                    key: "communityName",
                    minWidth: 100,
                    align: "center"
                },
                 {
                    title: "街巷/社",
                    key: "streetName",
                    minWidth: 100,
                    align: "center"
                },
                 {
                    title: "小区",
                    key: "projectName",
                    minWidth: 100,
                    align: "center"
                },
                 {
                    title: "楼栋",
                    key: "buildingName",
                    minWidth: 100,
                    align: "center"
                },
                 {
                    title: "门牌号/房号/户",
                    key: "houseNum",
                    minWidth: 100,
                    align: "center"
                },
                 {
                    title: "是否确认并同步",
                    minWidth: 100,
                    align: "center",
                    render:(h,params) =>{
                        return h('div',{},params.row.syncStatus ? params.row.syncStatus == 1 ?'未同步':params.row.syncStatus == 2 ?'同步成功':params.row.syncStatus == 3 ?'同步失败' + (params.row.syncError ? params.row.syncError == 1 ? '，社区架构已导入' : params.row.syncError == 2 ?'，导入的excel内数据有重复' :'' :'')  :'' :'')
                    }
                },
                //  {
                //     title: "排序",
                //     key: "seq",
                //     minWidth: 100,
                //     align: "center"
                // },
                 {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    minWidth: 300,
                    fixed:"right",
                    render: (h, params) => {
                        return h("div", [
                        h(
                            "Button",
                            {
                            props: {
                                type: "info",
                                size: "small"
                            },
                            style:{
                                display:params.row.syncStatus == 2?'none':'span'
                            },
                            on: {
                                click: () => {
                                    this.editRow = {}
                                    this.getDetail(params.row.draftId)
                                }
                            }
                            },
                            "修改"
                        ),
                        h(
                            "Button",
                            {
                            style: {
                                marginLeft: "10px"
                            },
                            props: {
                                type: "info",
                                size: "small"
                            },
                            on: {
                                click: () => {
                                    this.$Modal.confirm({
                                         title: "温馨提示",
                                        content: "<p>您正在删除数据，是否继续？</p>",
                                        onOk: () => {
                                            this.$post('/gx/pc/orgDraft/delete',{
                                                ids:params.row.draftId
                                            }).then(res=>{
                                                if(res.code == 200){
                                                    this.$Message.success({
                                                        content:'删除成功',
                                                        background:true
                                                    })
                                                    this.page = this.currentPage
                                                    this.getList()
                                                }else{
                                                    this.$Message.error({
                                                        content:res.desc,
                                                        background:true
                                                    })
                                                    return
                                                }
                                            })
                                        },
                                    })
                                    
                                }
                            }
                            },
                            "删除"
                        ),
                        ]);
                    }
                    }
            ],
            tableData:[],
            loading:false,
            page:1,
            total:0,
            pageSize:20,
            currentPage:1,
            // 搜索参数
            searchData:{
                name:'',
                syncStatus:'1',
                communityCode:''
            },
            syncLoading:false, //批量同步按钮
            operationModal:false,
            operationTip:false,
            editRow:{},
          
            excelStatus:false,
            uploadData:{
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                communityCode:this.$route.query.communityCode
            },
            exportAction:'',
            headers: {openId: window.sessionStorage.getItem('openId'),Authorization:window.sessionStorage.getItem('accessToken')},
            showExcel:0,
            nodeList: [],
            //异步加载社区
            loadingCommunity: false,
            nodeId:'',
            allNodeList:[],
            tableSelectList:[],
            loading1: false,
             //社区
             allCommunityList: [],
            firstCode:'',
            SelectNum:0,
        }
    },
    async created(){
       await this.getSelect()
       await this.getList()
       
    },
    methods:{
        // 返回按钮方法
       navigateBack() {
           if(this.$route.query.isback) this.$router.push('/mobilizationsystem');
           else this.$router.push('/area');
       },
       backClick(){
           if(this.$route.query.isLineHome && this.$route.query.isback){
               this.$router.push(`/mobilizationsystem?isLineHome=${this.$route.query.isLineHome}`)
           }else {
              this. $router.push(`/area?isLineHome=${this.$route.query.isLineHome}`)
           }
       },
        // 方法部分
        // 删除全部按钮方法
        deleteAll(){
            // let name = ''
            //  if(this.searchData.communityCode == ''){
            //      this.$Message.warning({
            //          content:'请先选择想要删除的社区',
            //          background:true
            //      })
            //      return
            //  }
            //  this.nodeList.map(item =>{
            //      if(item.value == this.searchData.communityCode){
            //          name = item.label
            //      }
            //  })
             this.$Modal.confirm({
                title: "温馨提示",
                content:
                  `<p>您正在删除当前社区的信息，此操作不可逆，是否继续</p>`,
                onOk: () => {
                    this.deleteJson(this.$route.query.communityCode)
                }
             })
        },
        // 导入成功回调事件
        excelSuccess(){
          this.excelStatus = false
          this.page = this.currentPage
          this.getList()
        },
        //修改excel导入弹窗状态
        excelModalData(status) {
            if(!status && this.operationTip){
                this.excelStatus = status;
                this.operationTip = false
            }else{
                this.excelStatus = status;
            }
            

        },
        importExcel(){
            this.nodeId = ''
            this.excelStatus = true
            this.showExcel = this.showExcel + 1
            this.exportAction =  window.vue.getProxy()["/orgzz"].target + "/upload/region/importCommunityDraft";
            },
        // 关闭模态框
        operationStatus(status){
            this.operationModal = status
        },
        // 模态框确认按钮
        saveOperation(){
            if(!this.editRow.streetName || this.editRow.streetName == ''){
                this.$Message.warning({
                    content:'街道名称不能为空',
                    background:true
                })
                return
            }
            if(!this.editRow.projectName || this.editRow.projectName == ''){
                this.$Message.warning({
                    content:'小区名称不能为空',
                    background:true
                })
                return
            }
            if(!this.editRow.buildingName || this.editRow.buildingName == ''){
                this.$Message.warning({
                    content:'楼栋名称不能为空',
                    background:true
                })
                return
            }
            if(!this.editRow.houseNum || this.editRow.houseNum == ''){
                this.$Message.warning({
                    content:'房号/门牌号不能为空',
                    background:true
                })
                return
            }
           
            this.updateRow()
        },
        // 选择了表格的tableSelect事件
        tableSelect(val){
            console.log(this.tableSelectList)
            this.SelectNum = val.length
            this.tableSelectList = []
            if(val.length != 0){
                val.map(item =>{
                    this.tableSelectList.push(item.draftId)
                    
                })
            }
        },
        // 批量同步按钮
        syncBtn(){
            console.log(this.tableSelectList);
            console.log(this.searchData.name);
            console.log(this.searchData.syncStatus);
            console.log(this.searchData.communityCode);
            if(this.tableSelectList.length == 0 ){
                this.$Message.warning({
                    content:'请勾选数据后再进行批量同步',
                    background:true
                })
                return
            }
            if(this.tableSelectList.length != 0){
                this.$Modal.confirm({
                        title: "温馨提示",
                        content: "<p>确认并同步当前查勾选的数据吗？</p>",
                        onOk: () => {
                            this.syncGet()
                        },
                })
                
            }else {
                if(!this.searchData.communityCode || this.searchData.communityCode == ''){
                    this.$Message.warning({
                        content:'请填写社区后再进行批量确认并同步',
                        background:true
                    })
                    return
                }
                this.$Modal.confirm({
                        title: "温馨提示",
                        content: "<p>确认并同步当前查询到的所有数据吗？</p>",
                        onOk: () => {
                            this.otherSyncGet()
                        },
                })
                
            }
        },
         // 搜索按钮
        searchBtn(){
            this.page = 1
            this.getList()
        },
        // 重置按钮
        restBtn(){
            this.searchData = {
                name:'',
                syncStatus:'',
                // communityCode:''
            }
            this.getList()
        },
        // 改变分页
        hadlePageSize(val){
            this.page  = val.page
            this.pageSize = val.pageSize
            this.getList()
        },

        // 接口部分
        async getList(){
            // if(this.searchData.syncStatus == 0)
            // this.searchData.syncStatus = ''
            // this.searchData.syncStatus = Number(this.searchData.syncStatus)
            this.loading = true
           await this.$get('/gx/pc/orgDraft/queryByPage',{
                page:this.page,
                pageSize:this.pageSize,
                keyword:this.searchData.name,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                syncStatus:this.searchData.syncStatus?this.searchData.syncStatus:"",
                communityCode:this.$route.query.communityCode
            }).then(res=>{
                  this.loading = false
                if(res.code == 200){
                    // if(res.dataList && res.dataList.length != 0){
                    //     res.dataList.map(item =>{
                    //         if(item.syncStatus == 3){
                    //             item._disabled = true
                    //         }
                    //     })
                    // }
                    if(this.searchData.syncStatus == '')
                    this.searchData.syncStatus == 0
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        // id集合同步
        syncGet(){
            this.syncLoading = true
            this.$post('/gx/pc/orgDraft/syncDataByIds',{
                ids:this.tableSelectList.join(',')
            }).then(res=>{
                this.syncLoading = false
                if(res.code == 200){
                     this.$Message.success({
                         content:`批量确认并同步成功${res.data}条`,
                         background:true
                     })
                     this.SelectNum = 0
                     this.tableSelectList = []
                     this.page = this.currentPage
                     this.getList()
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        //id集合删除
        delIds(){
            // console.log(this.tableSelectList)
            if(this.tableSelectList.length == 0){
                this.$Message.warning({
                    content:`请勾选数据后再进行批量删除`,
                    background:true
                })
                return
            }
            this.$Modal.confirm({
                 title: "温馨提示",
                content: "<p>您正在删除数据，是否继续？</p>",
                onOk: () => {
                   this.syncLoading = true
            this.$post('/gx/pc/orgDraft/delete',{
                ids:this.tableSelectList.join(',')
            }).then(res=>{
                this.syncLoading = false
                if(res.code == 200){
                     this.$Message.success({
                         content:`删除成功`,
                         background:true
                     })
                     this.SelectNum = 0
                     this.tableSelectList = []
                     this.page = this.currentPage
                     this.getList()
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
                },
            })



            
        },
        // 组合条件同步
        otherSyncGet(){
            this.syncLoading = true
            this.$post('/gx/pc/orgDraft/syncDataList',{
                keyword:this.searchData.name,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                syncStatus:this.searchData.syncStatus,
                communityCode:this.searchData.communityCode
            }).then(res=>{
                this.syncLoading = false
                if(res.code == 200){
                     this.$Message.success({
                         content:`批量确认并同步成功${res.data}条`,
                         background:true
                     })
                    this.page = this.currentPage
                    this.getList()
                    this.tableSelectList = []
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        getDetail(data){
            this.$get('/gx/pc/orgDraft/queryById',{
                id:data
            }).then(res=>{
                if(res.code == 200){
                    this.operationModal = true
                    this.editRow = res.data
                }else{
                    this.$Message.error({
                        content:'数据详情获取失败',
                        background:true
                    })
                    return

                }
            })
        },
        updateRow(){
            this.$post('/gx/pc/orgDraft/update',{
                ...this.editRow
            },{'Context-Type':'application/json'}).then(res=>{
                if(res.code == 200){
                    this.operationModal = false
                    this.operationTip = false
                    this.$Message.success({
                        content:'修改成功',
                        background:true
                    })
                    this.page = this.currentPage
                    this.getList()
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        // 删除全部数据，按社区
        deleteJson(orgCode){
            this.$post('/gx/pc/orgDraft/deleteOrg',{
                orgCode
            }).then(res =>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'删除成功',
                        background:true
                    })
                    this.page = 1
                    this.pageSize = 20
                    this.SelectNum = 0
                    this.getList()
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },

         //异步加载社区
    loadingCommunityFn(query) {
       if(query !== '' && !Number(query)) {
        this.loadingCommunity = true;
       this.nodeList = this.allNodeList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
       if(this.nodeList.length > 50) this.nodeList.length = 50;
        this.loadingCommunity = false;
      } else if(Number(query)) {
        return;
      }
      else {
        this.nodeList = [];
      }
    },
    async getSelect(){
      await this.$get("/gx/pc/staff/selectDatsScopeAndFilterLevel", {
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
          filterLevel: 5,
          oemCode: parent.vue.oemInfo.oemCode
        })
          .then((res) => {
            if (res.code === "200"&&res.dataList&&res.dataList.length>0) {
              // this.getTree(this.communityCode);   //第一次进来默认获取第一个社区数据
              this.nodeList = res.dataList.map((item) => {
                return {
                  value: item.orgCode,
                  label: item.orgName,
                  projectCode: item.projectCode
                };
              });
              this.allNodeList = JSON.parse(JSON.stringify(this.nodeList));
              if(this.nodeList.length > 50) {
                this.nodeList.length = 50;
              }
              this.searchData.communityCode = this.nodeList[0].value
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getTree(val){
        this.nodeId = val
        this.uploadData.communityCode = this.nodeId
    },
    getSearchTree(val){
        if(val != '' && val != undefined){
            this.searchData.communityCode = val
            this.getList()
        }
        
     }
    }
    
}
</script>

<style lang="less" scoped>
 //编写css
   /deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
  }
  .export-modal{
  /deep/ #modal_contentarea{
        overflow: visible !important;
        height: 270px;
    }
}
</style>